import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/how-to-win-a-bidding-war-in-a-competitive-san-diego-market"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_ How to Win a Bidding War.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Unsurprisingly, the real estate market in sunny San Diego is as hot as the weather.
                With the booming Southern California economy, picturesque beaches, and vibrant
                neighborhoods, many feel house hunting is like entering a high-stakes arena.
              </CommonParagraph>
              <CommonParagraph>
                If you’re wondering, “In the competitive San Diego realty market, how do I win the
                bidding war?” Here are seven strategies to help!
              </CommonParagraph>
              <Heading type="h2">1. Team Up With a Pro</Heading>
              <CommonParagraph>
                Don’t take on the housing market and bidding wars alone; partner with a
                knowledgeable real estate agent. By working with a professional experienced in the
                ins and outs of the{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="San Diego Market"
                />{" "}
                , you’ll get invaluable advice and expert guidance. Their negotiating skills could
                mean the difference between victory and defeat!
              </CommonParagraph>
              <Heading type="h2">2. Establish and Adhere to a Budget</Heading>
              <CommonParagraph>
                Knowing your budget inside and out before diving into the market is essential. By
                setting clear financial boundaries you won’t cross, you establish realistic
                expectations and are less likely to get swept up in bidding wars that stretch beyond
                your means. Consider getting pre-approved for a mortgage to lock in that budget and
                show you’re a serious contender.
              </CommonParagraph>
              <Heading type="h2">3. Be Decisive</Heading>
              <CommonParagraph>
                It’s no secret that the San Diego real estate market is competitive. Hesitation
                could cost you the home of your dreams, so act swiftly and decisively. If you find a
                property that checks most of your boxes, a home you could see yourself in, submit an
                offer as soon as possible.
              </CommonParagraph>
              <CommonParagraph>
                It’s impossible to act decisively without doing prep work, like knowing{" "}
                <BlogLink
                  url="https://selbysellssd.com/different-types-of-home-loans/"
                  text="which type of home loan is right for you"
                />
                , so don’t start house hunting before you’re ready. If you do, you may find the home
                of your dreams but miss out on it because you weren’t prepared.
              </CommonParagraph>
              <Heading type="h2">4. Submit a Strong Offer</Heading>
              <CommonParagraph>
                First impressions matter, bidding wars included! It’s tempting to attempt
                negotiating a lower price, but this strategy often backfires in a low-inventory
                market. One of the best ways to stand out in a bidding war is to submit a solid
                initial offer with favorable terms for the seller. Doing so entices the seller
                immediately, making them more inclined to choose you.
              </CommonParagraph>
              <Heading type="h2">5. Make Yourself Available</Heading>
              <CommonParagraph>
                In the world of real estate, things move quickly. If you’re locked in a bidding war,
                one of the most important things you can do is stay available. Taking three or four
                days to respond to an email or return a call from your real estate agent could mean
                missing out on your dream home. Give yourself a competitive edge by quickly yet
                thoroughly responding to all communication.
              </CommonParagraph>
              <Heading type="h2">6. Keep an Open Mind</Heading>
              <CommonParagraph>
                Bidding wars demand flexibility, so if you find yourself competing for a property,
                keep an open mind. Consider offering to cover certain closing costs or accommodating
                the seller’s preferred closing timeline. These small gestures can make your offer
                more appealing and increase the likelihood of it being accepted over the others.
              </CommonParagraph>
              <Heading type="h2">7. Don’t Give Up</Heading>
              <CommonParagraph>
                Bidding wars are roller coasters of emotions. You can do everything right and still
                miss out on a property. With many factors at play, including the seller’s
                preferences and perspectives, bidding wars don’t always go how you’d like them to.
                Stay positive and persistent! The{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="right home for you"
                />{" "}
                is out there, so keep putting your best bidding war foot forward.
              </CommonParagraph>
              <Heading type="h2">Find Your Home With The Selby Team</Heading>
              <CommonParagraph>
                Ready to hit the market? Team up with The Selby team! Our experts are here with you
                from start to finish, including throughout a bidding war, so that you can secure the
                perfect property. <ContactSlideoutLink text="Let’s talk homes today" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
